import React from "react"
import Layout from "components/layout"
import ReadMoreButton from "../components/readMoreButton";

const NotFoundPage = () => (
  <Layout>
    <div className="notfound-page">
      <h2>404 Not Found</h2>
      <p className="description">
        お探しのページが見つかりません<br />
        URLが間違っているか、削除された可能性があります。
      </p>
      <ReadMoreButton href="/" title="トップページ" />
    </div>
  </Layout>
)

export default NotFoundPage
